/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { LabelListResponse } from '../models/LabelListResponse';
import type { LabelResponse } from '../models/LabelResponse';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
    providedIn: 'root',
})
export class LabelsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get Labels
     * @returns LabelListResponse Successful Response
     * @throws ApiError
     */
    public listLabels(): Observable<LabelListResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/labels',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Label Types By Name
     * Returns all label records associated with the given label name
     * @param labelName
     * @returns LabelResponse Successful Response
     * @throws ApiError
     */
    public getLabelTypesByName(
        labelName: string,
    ): Observable<Array<LabelResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/labels/{label_name}',
            path: {
                'label_name': labelName,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Label Type By Name And Id
     * Returns a single label record associated with the given label name and id
     * @param labelName
     * @param labelId
     * @returns LabelResponse Successful Response
     * @throws ApiError
     */
    public getLabelTypeByNameAndId(
        labelName: string,
        labelId: string,
    ): Observable<LabelResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/labels/{label_name}/{label_id}',
            path: {
                'label_name': labelName,
                'label_id': labelId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
