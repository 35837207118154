import {AppEnvironment} from '@shared/types/environment';

export const SERVICE_HOST = 'uat.ithriv.org';
export const RESOURCE_API = `https://${SERVICE_HOST}`;
export const RESOURCE_API_ALT = `https://api-${SERVICE_HOST}`;

export const environment: AppEnvironment = {
  use_header_auth: false,
  envName: 'staging',
  production: true,
  api: RESOURCE_API,
  api_alt: RESOURCE_API_ALT,
  gtm_container_id: '__GTM_CONTAINER_ID__',
  landing_service: [
    {name: 'UVA', url: 'https://uvathrivt.healthsystem.virginia.edu'},
    {name: 'Carilion', url: 'https://ithrivepoc.carilionaws.com'},
    {name: 'Virginia Tech', url: 'https://vtthrivt.arc.cloud.vt.edu'},
  ],
};
