import {Component} from '@angular/core';

@Component({
  selector: 'app-commons',
  templateUrl: './commons.component.html',
  styleUrls: ['./commons.component.scss'],
})
export class CommonsComponent {
  constructor() {}
}
