import {animate, state, style, transition, trigger} from '@angular/animations';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpRequest} from '@angular/common/http';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import JSZip from 'jszip';
import {of} from 'rxjs';
import {catchError, last, map, tap} from 'rxjs/operators';
import {ParsedError} from '@form-controls/error-message/error-message.component';
import {ErrorSnackbarComponent} from '@form-controls/error-snackbar/error-snackbar.component';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {ALLOWED_MIME_TYPES} from '@shared/constants/constants';
import {FileUploadModel} from '@shared/types/commons-types';
import {User} from '@shared/types/user';

const bPerKb = 1024;
const kbPerMb = 1024;
export const MAX_FILENAME_LENGTH = 100;
export const MIN_FILE_SIZE = 0; // 0 bytes

@Component({
  selector: 'app-commons-file-upload',
  templateUrl: './commons-file-upload.component.html',
  styleUrls: ['./commons-file-upload.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({opacity: 100})),
      transition('* => void', [animate(300, style({opacity: 0}))]),
    ]),
  ],
})
export class CommonsFileUploadComponent {
  /** Link text */
  @Input() text = '';
  /** Name used in form which will be sent in HTTP request. */
  @Input() param = 'file';
  /** Target URL for file uploading. */
  @Input() target = undefined;
  @Input() user: User;
  /** File extension that accepted, same as 'accept' of <input type="file" />.
   By the default, it's set to 'image/*'. */
  @Input() accept = ALLOWED_MIME_TYPES;
  @Input() color: ThemePalette = 'warn';
  @Input() buttonType = 'mat-raised-button';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() uploadComplete = new EventEmitter<string>();

  files: Array<FileUploadModel> = [];

  constructor(
    private _http: HttpClient,
    private cas: CommonsApiService,
    private snackBar: MatSnackBar,
  ) {}

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({
          data: file,
          state: 'in',
          inProgress: false,
          progress: 0,
          canRetry: false,
          canCancel: true,
        });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  cancelFile(file: FileUploadModel) {
    file.sub.unsubscribe();
    this.removeFileFromArray(file);
  }

  retryFile(file: FileUploadModel) {
    this.uploadFile(file);
    file.canRetry = false;
  }

  private async uploadFile(file: FileUploadModel) {
    if (file.data.size <= MIN_FILE_SIZE) {
      this.displayError(`File is empty. Please upload a file that is greater than ${MIN_FILE_SIZE} bytes.`);
      this.removeFileFromArray(file);
      return;
    }

    if (file.data.name.length > MAX_FILENAME_LENGTH) {
      this.displayError(
        `Filename is too long. Please rename the file to something shorter than ${MAX_FILENAME_LENGTH} characters.`,
      );
      this.removeFileFromArray(file);
      return;
    }

    const fd = new FormData();
    console.log(file.data.type);
    // Check if file is already zipped.
    if (file.data.type === 'application/zip' || file.data.type === 'application/x-zip-compressed') {
      // File is zipped. No need to zip again.
      fd.append(this.param, file.data);
      fd.append('compressed', 'False');
    } else {
      // File is not zipped. Compress it before uploading.
      const zip = new JSZip();
      zip.file(file.data.name, file.data);
      const zippedBlob = await zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {level: 6},
      });

      const zipFile = new File([zippedBlob], file.data.name + '.zip');
      fd.append(this.param, zipFile);
      fd.append('compressed', 'True');
    }
    const req = new HttpRequest('POST', this.target, fd, {
      headers: this.cas.getRequestHeaders(this.user),
      reportProgress: true,
    });

    file.inProgress = true;
    file.sub = this._http
      .request(req)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(_ => {}),
        last(),
        catchError((_: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          console.log(_);
          this.displayError(JSON.stringify(_.error));
          return of(`${file.data.name} upload failed.`);
        }),
      )
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          this.removeFileFromArray(file);
          this.uploadComplete.emit(event.body);
        }
      });
  }

  private uploadFiles() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.value = '';

    this.files.forEach(file => {
      this.uploadFile(file);
    });
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  private displayError(errorString?: string, parsedError?: ParsedError) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: {errorString, parsedError, action: 'Ok'},
      duration: 50000,
      panelClass: 'snackbar-warning',
    });
  }
}
