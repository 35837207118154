import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DatasetResponse} from '@services/landing-service';

export interface HsdUploadDialogData {
  confirm: boolean;
  dataset: DatasetResponse;
}

@Component({
  selector: 'app-hsd-upload-dialog',
  templateUrl: './hsd-upload-dialog.component.html',
  styleUrls: ['./hsd-upload-dialog.component.scss'],
})
export class HsdUploadDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: HsdUploadDialogData,
    public dialogRef: MatDialogRef<HsdUploadDialogComponent>,
  ) {}

  onNoClick() {
    this.data.confirm = false;
    this.dialogRef.close(this.data);
  }

  onSubmit() {
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }
}
