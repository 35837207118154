import {Component, Input} from '@angular/core';
import {Resource} from '@shared/types/resource';

@Component({
  selector: 'app-resource-search',
  templateUrl: './resource-search.component.html',
  styleUrls: ['./resource-search.component.css'],
})
export class ResourceSearchComponent {
  @Input() resource: Resource;

  constructor() {}
}
