/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { OrganizationCreate } from '../models/OrganizationCreate';
import type { OrganizationListResponse } from '../models/OrganizationListResponse';
import type { OrganizationPatch } from '../models/OrganizationPatch';
import type { OrganizationPut } from '../models/OrganizationPut';
import type { OrganizationResponse } from '../models/OrganizationResponse';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
    providedIn: 'root',
})
export class OrganizationsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get Organizations
     * @returns OrganizationListResponse Successful Response
     * @throws ApiError
     */
    public listOrganizations(): Observable<OrganizationListResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/organizations',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create Organization
     * @param requestBody
     * @returns any Successful Response
     * @returns string Created
     * @throws ApiError
     */
    public createOrganization(
        requestBody: OrganizationCreate,
    ): Observable<any | string> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/organizations',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'Location',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Organization
     * Gets data for organization
     * @param organizationId
     * @returns OrganizationResponse Successful Response
     * @throws ApiError
     */
    public getOrganization(
        organizationId: string,
    ): Observable<OrganizationResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/organizations/{organization_id}',
            path: {
                'organization_id': organizationId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Organization
     * @param organizationId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateOrganization(
        organizationId: string,
        requestBody: OrganizationPut,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/organizations/{organization_id}',
            path: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Patch Organization
     * @param organizationId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public editOrganization(
        organizationId: string,
        requestBody: OrganizationPatch,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/organizations/{organization_id}',
            path: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
