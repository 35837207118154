import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {getNavItems} from '@commons/commons-nav/commons-nav-util';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {CommonsSearchDataset, CommonsSearchProject, getRoleTooltip} from '@shared/types/commons-types';
import {NavItem} from '@shared/types/nav-item';
import {Resource} from '@shared/types/resource';
import {scrollToRouteParam} from '@utilities/scroll-to-selector';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-public-project',
  templateUrl: './public-project.component.html',
  styleUrls: ['./public-project.component.scss'],
})
export class PublicProjectComponent implements OnInit {
  project: CommonsSearchProject;
  datasets: CommonsSearchDataset[];
  datasetsDataSource: MatTableDataSource<CommonsSearchDataset>;
  showConfirmDelete = false;
  error: String;
  createNew = false;
  displayedColumns: string[] = ['name', 'isMetadataPublic', 'isDataPublic', 'download'];
  projectResources: Resource[];
  getRoleTooltip = getRoleTooltip;
  contentBeingLoaded: string;
  navItems: NavItem[];

  constructor(
    private api: ResourceApiService,
    private cas: CommonsApiService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {}

  get isLoading(): boolean {
    return !(this.project && this.datasets);
  }

  get projectId(): string {
    return this.route.snapshot.paramMap.get('project_id');
  }

  get projectName(): string {
    return this.isLoading ? '' : `Project: ${this.project.name || ''}`;
  }

  ngOnInit() {
    this.tryLoad();
  }

  async tryLoad() {
    this.contentBeingLoaded = 'Project';
    await this.loadProject();
    this.loadNavItems();
    this.contentBeingLoaded = 'Project Datasets';
    await this.fetchDatasets();
    this.contentBeingLoaded = 'Project Resources';
    await this.loadProjectResources();
    await scrollToRouteParam(this.route);
  }

  async loadProject() {
    const resp = await lastValueFrom(this.api.searchProjects(null, {q: '*', id: this.projectId}));
    this.project = <CommonsSearchProject>(<unknown>resp.hits.map(x => x._source))[0];
  }

  private loadNavItems() {
    this.navItems = getNavItems(['commons-public', 'commons-project-public'], this.projectId);
  }

  private updateDatasetsDataSource() {
    this.datasetsDataSource = new MatTableDataSource<CommonsSearchDataset>(this.datasets);
  }

  private async loadProjectResources() {
    this.projectResources = await this.api.loadProjectResources(this.project.portal_resources_urls);
  }

  private async fetchDatasets() {
    this.datasets = await this.api.getDatasets('*', null, true, {associated_projects: this.projectId}, true);
    this.updateDatasetsDataSource();
  }
}
