/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProjectFileCategoryID {
    CONTRACT = 'contract',
    PROTOCOL = 'protocol',
    IRB_APPLICATION = 'irb-application',
    IRB_APPROVAL = 'irb-approval',
    DATA_SECURITY_PLAN = 'data-security-plan',
    PUBLICATION = 'publication',
    OTHER = 'other',
}
